.title {
	color: #949494;
	font-size: 13px;
	padding-left: 4px;
}

.inputParent {
	display: flex;
	flex-direction: row;
}

.input {
	font-family: inherit;
	margin-top: 6px;
	display: inline-block;
	border-radius: 7px;
	border-style: solid;
	border-width: 1px;
	border-color: #D5D5D5;
	text-align: left;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 17px;
	padding-right: 17px;
	flex: 1;
	width: 40%;
	font-size: inherit;
	margin-bottom: 0px;
	color: inherit;
	line-height: auto;
	font-size: 15px;
}

.input:focus {
	outline: none;
	border-width: 2px;
	border-color: #017cf6;
	border-radius: 7px;
	margin-bottom: 0px;
	padding-left: 16px;
	padding-top: 11px;
	padding-bottom: 11px;
}

.title {
	color: #949494;
	font-size: 13px;
	padding-left: 4px;
}

.selectorParent {
	display: flex;
	flex-direction: row;
}

.selector {
	margin-top: 6px;
	display: inline-block;
	border-radius: 7px;
	border-style: solid;
	border-width: 1px;
	border-color: #D5D5D5;
	text-align: center;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 5px;
	padding-right: 5px;
	flex: 1;
	cursor: pointer;
	width: 40%;
	user-select: none;
}

.selector:nth-child(even) {
	margin-left: 10px;
}

.selected {
	border-width: 2px;
	border-color: #017cf6;
	border-radius: 7px;
}

.notSelected {
	line-height: 1.4;
	opacity: 0.6;
}

.noneSelected {
	opacity: 0.9;
	margin-bottom: 2px;
}

.button {
	width: 100%;
	background-color: #017cf6;
	text-align: center;
	color: #fff;
	cursor: pointer;
	border-radius: 6px;
	padding-top: 17px;
	padding-bottom: 17px;
	padding-left: 5px;
	padding-right: 5px;
	transition: background-color 0.3s;
	user-select: none;
}

.button:hover {
	background-color: #0279ef;
}

.disabled {
	cursor: not-allowed;
	background-color: #D5D5D5;
}

.disabled:hover {
	background-color: #D5D5D5;
}

.loading {
	cursor: progress;
}
body {
	background-color: #FDFDFD;
}

.wrapper {
	background-color: transparent;
	max-width: 490px;
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding-top: 40px;
	padding-bottom: 40px;
}

.whiteBox {
	background-color: #fff;
	position: relative;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 80px);
	padding: 40px;
	margin-top: 20px;
	border-radius: 6px;
	box-shadow: 1px 1px 10px 0px #0000000a;
	transition: margin-top 0.3s;
	height: auto;
	transition: height 0.3s;
}

.description {
	font-size: 18px;
	opacity: 0.9;
	text-align: center;
}

.price {
	margin-top: 10px;
	/* margin-bottom: 17px; */
	color: #017cf6;
	/* color: #525252; */
	font-size: 35px;
	opacity: 1;
	text-align: center;
}

.priceNumber {
	border-color: #017cf6;
	border-style: none;
	border-width: 0 0 1px 0;
	display: inline-block;
	/* cursor: help; */
	padding-left: 3px;
	padding-right: 3px;
}

.priceDetails {
	color: #949494;
	font-size: 12px;
}

.currencyValue {
	display: inline-block;
	font-size: 17px;
	margin-left: 7px;
}

.footer {
	margin-top: 15px;
	width: 80%;
	position: relative;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	opacity: 0.6;
	text-align: center;
	font-size: 11px;
	transition: margin-top 0.3s;
}

.footer a {
	color: #333333;
}

@media screen and (max-width: 500px) {
	body {
		background-color: #ffffff;
	}
	.whiteBox {
		margin-top: 0px;
		padding-top: 30px;
		box-shadow: 0px 0px 0px 0px;
	}
	.footer {
		margin-top: 0px;
	}
}

.box {
	border-color: rgba(241, 241, 241, 0.842);
	border-style: none;
	border-width: 1px;
	padding: 15px;
	padding-top: 25px;
	padding-bottom: 25px;
	border-radius: 7px;
	padding: 0px;
}

.infoBox {
	padding: 15px;
	border-radius: 7px;
	margin-top: 30px;
	background-color: rgba(241, 241, 241, 0.442);
	font-size: 12px;
	opacity: 0.8;
}

.globalError {
	text-align: center;
}

.skeletonContent {
	animation: skeletonContentAnimation 1s infinite alternate;
	animation-timing-function: ease-in-out;
}

@keyframes skeletonContentAnimation {
	from { background-color: rgb(233, 233, 233); }
	to { background-color: rgb(211, 211, 211); }
}

.inlineError {
	padding: 15px;
	border-radius: 7px;
	margin-top: 30px;
	background-color: rgba(241, 241, 241, 0.442);
	font-size: 14px;
	opacity: 0.8;
	background-color: rgb(251, 234, 234);
	color: rgb(187, 46, 52);
}

.logo {
	width: 100px;
	height: 50px;
	background-image: url(./assets/logo.png);
	background-size: 100px;
	background-color: transparent;
	background-repeat: no-repeat;
}

.size_small {
	width: 100px;
	height: 50px;
	background-size: 100px;
}

.size_medium {
	width: 130px;
	height: 65px;
	background-size: 130px;
}

.size_big {
	width: 180px;
	height: 90px;
	background-size: 180px;
}
